import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


export default function Snackbars(props) {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={10000}
      >
        <Alert
          onClose={handleClose}
          severity={props.variant}
          sx={{ width: '100%' }} 
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  )
}