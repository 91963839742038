import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, Table, Button, Input, Label, FormFeedback, Modal } from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import ImageGallery from 'react-image-gallery';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbars from "../../Utils/Snackbars";
import { checkFileValidation } from "../../Utils/Common";
import useRequests from '../../Utils/useRequests';
import useDecryptedAuth from "../../Utils/useDecryptedAuth";
import i18next from 'i18next';
import volgreen from "../../assets/images/Picture1.png";
import Jose1 from "../../assets/images/Picture2.png";
import Alexandre from "../../assets/images/Picture3.png";
import Josep from "../../assets/images/Picture4.png";
import Ramon from "../../assets/images/Picture7.png";
import Jose2 from "../../assets/images/Picture6.png";

const PropertyDetails = props => {
  //meta title
  document.title = i18next.t('Property_Detail PG document title');
  const { getRequest, postRequest, getRequestForBinance, postRequestByFormData } = useRequests();
  const decryptedAuth = useDecryptedAuth();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async () => {
    
    if(decryptedAuth.kyc_status !== 'Approved') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: i18next.t('For this feature, KYC has been required.'),
      }));
    }
    else {
      let EuroInvestment = 0;
      if (validation1.values.number_of_tokens !== '' || parseFloat(validation1.values.number_of_tokens) > 0) {
        EuroInvestment = parseFloat(validation1.values.number_of_tokens) * PropertyInfo.token_price;
      }

      if(validation1.values.is_used_euro_wallet) {
        let EuroAvailableBalance = parseFloat(CryptoData.euro_available_balance);
        if (TransactionData.length > 0) {
          let filteredData = TransactionData.filter(item => item.status === 'Pending');
          let TotalPendingAmount = 0;
          filteredData.forEach(element => {
            TotalPendingAmount = TotalPendingAmount + parseFloat(element.total_amount);
          });
          EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
        }
        if (PropertyCryptoTransactionData.length > 0) {
          let filteredData = PropertyCryptoTransactionData.filter(item => item.status === 'Pending');
          let TotalPendingAmount = 0;
          filteredData.forEach(element => {
            TotalPendingAmount = TotalPendingAmount + parseFloat(element.used_euro_wallet_amount);
          });
          EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
        }
        
        if(EuroInvestment > EuroAvailableBalance) {
          validation2.setValues({
            ...validation2.values,
            transfer_euro: EuroInvestment-EuroAvailableBalance,
          });
        }
        else {
          validation2.setValues({
            ...validation2.values,
            transfer_euro: 0,
          });
        }
      }
      else {
        validation2.setValues({
          ...validation2.values,
          transfer_euro: EuroInvestment,
        });
      }
      if(validation1.values.number_of_tokens !== '' && parseFloat(validation1.values.number_of_tokens) > 0){
        setStandardModal1(true);
      }
      
    }
  }

  const handleClickCheckbox = (is_used_euro_wallet, number_of_tokens) => {
    let isChecked = validation1.values.is_used_euro_wallet;
    if(is_used_euro_wallet) {
      isChecked = !validation1.values.is_used_euro_wallet;
      validation1.setValues({ ...validation1.values, is_used_euro_wallet: isChecked });
    }

    let submit3 = document.getElementById('submit3');
    if (isChecked && validation1.values.currency === 'Euro' && number_of_tokens !== '' && parseFloat(number_of_tokens) > 0) {
      let EuroInvestment = parseFloat(number_of_tokens) * PropertyInfo.token_price;

      let EuroAvailableBalance = parseFloat(CryptoData.euro_available_balance);
      if (TransactionData.length > 0) {
        let filteredData = TransactionData.filter(item => item.status === 'Pending');
        let TotalPendingAmount = 0;
        filteredData.forEach(element => {
          TotalPendingAmount = TotalPendingAmount + parseFloat(element.total_amount);
        });
        EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
      }
      if (PropertyCryptoTransactionData.length > 0) {
        let filteredData = PropertyCryptoTransactionData.filter(item => item.status === 'Pending');
        let TotalPendingAmount = 0;
        filteredData.forEach(element => {
          TotalPendingAmount = TotalPendingAmount + parseFloat(element.used_euro_wallet_amount);
        });
        EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
      }
      
      if(EuroInvestment <= EuroAvailableBalance) {
        submit3.textContent = i18next.t('Submit');
        submit3.classList.add('submit');
      }
      else {
        submit3.textContent = i18next.t('Invest');
        submit3.classList.remove('submit');
      }
    } else {
      submit3.textContent = i18next.t('Invest');
      submit3.classList.remove('submit');
    }
  };

  const [YourInvestment, setYourInvestment] = React.useState(0);
  const [ShareOfTotalEquity, setShareOfTotalEquity] = React.useState(0);
  const handleChangeNumberOfTokens = async (tempNumberOfTokens, tempCurrency) => {
    setYourInvestment(0);
    setShareOfTotalEquity(0);
    if (parseFloat(tempNumberOfTokens) > 0 && tempCurrency !== '') {
      let EuroInvestment = tempNumberOfTokens * PropertyInfo.token_price;
      let TempYourInvestment = EuroInvestment;

      if (tempCurrency === 'BTC' || tempCurrency === 'ETH' || tempCurrency === 'MATIC') {
        let RateData = BinanceRatesData.find(item => item.symbol === tempCurrency + 'EUR');
        TempYourInvestment = EuroInvestment / parseFloat(RateData.price);
        TempYourInvestment = TempYourInvestment.toFixed(8);
      }
      else if (tempCurrency === 'USDT' || tempCurrency === 'USDC') {
        let RateData = BinanceRatesData.find(item => item.symbol === 'EUR' + tempCurrency);
        TempYourInvestment = EuroInvestment * parseFloat(RateData.price);
        TempYourInvestment = TempYourInvestment.toFixed(8);
      }

      setYourInvestment(TempYourInvestment);
      setShareOfTotalEquity(((tempNumberOfTokens / PropertyInfo.total_token) * 100).toFixed(2));
    }

    if(validation1.values.currency === 'Euro' && validation1.values.is_used_euro_wallet) {
      handleClickCheckbox(false, tempNumberOfTokens);
    }
  }

  const [WrongModalOpen, setWrongModalOpen] = React.useState(false);
  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      currency: 'BTC',
      number_of_tokens: '',
      is_used_euro_wallet: false,
    },
    validationSchema: Yup.object({
      currency: Yup.string().required(i18next.t('Property_Detail PG Please select currency')),
      number_of_tokens: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Property_Detail PG Number of Tokens is required')),
    }),
    onSubmit: async (values, openModal = false) => {
      if(openModal) {
        let submit3 = document.getElementById('submit3');
        if (submit3.classList.contains('submit')) {
          // Open the "wrong modal"
          setWrongModalOpen(true);
        } else {
          togStandardModal1();
        }
      }
      else {
        if(decryptedAuth.kyc_status !== 'Approved') {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('For this feature, KYC has been required.'),
          }));
        }
        else {
          try {
            let EuroInvestment = parseFloat(YourInvestment);
            if (values.currency === 'BTC' || values.currency === 'ETH' || values.currency === 'MATIC') {
              let RateData = BinanceRatesData.find(item => item.symbol === values.currency + 'EUR');
              EuroInvestment = Math.round(EuroInvestment * parseFloat(RateData.price));
            }
            else if (values.currency === 'USDT' || values.currency === 'USDC') {
              let RateData = BinanceRatesData.find(item => item.symbol === 'EUR' + values.currency);
              EuroInvestment = Math.round(EuroInvestment / parseFloat(RateData.price));
            }

            if (parseFloat(EuroInvestment) < parseFloat(PropertyInfo.minimum_investment_allocation)) {
              setAlertData((AlertData) => ({
                ...AlertData,
                variant: 'error',
                message: i18next.t('Property_Detail PG Minimum investment Allocation is required'),
              }));
            }
            else {
              let submit1 = document.getElementById('submit1');
              submit1.disabled = true;
              submit1.textContent = i18next.t('Processing...');

              const jsonpar = JSON.stringify({
                p_id: 1,
                currency: values.currency,
                number_of_tokens: values.number_of_tokens,
                is_used_euro_wallet: values.is_used_euro_wallet,
              });
              const res = await postRequest('property-invest', jsonpar);
              if (res && res.code === 200) {
                if (res.data.length > 0 && res.data !== '') {
                  window.open(res.data, '_blank');
                }
                else {
                  validation1.setValues({
                    ...validation1.values,
                    currency: 'BTC',
                    number_of_tokens: '',
                    is_used_euro_wallet: false,
                  });
                  validation2.setValues({
                    ...validation2.values,
                    transfer_euro: 0,
                    transaction_information: '',
                    conditions: false,
                  });
                  setFile(null);
                  setFileName('');

                  setYourInvestment(0);
                  setShareOfTotalEquity(0);
                  handleLoadGetBinanceRates();
                  handleLoadGetCryptoData();
                  handleLoadGetTransactionData();
                  handleLoadGetPropertyCryptoTransactionData();
                  setTimeout(() => {
                    handleClickCryptoBalanceRefresh();
                  }, 7000);

                  setAlertData((AlertData) => ({
                    ...AlertData,
                    variant: 'success',
                    message: res.message,
                  }));
                }
              }
              else {
                setAlertData((AlertData) => ({
                  ...AlertData,
                  variant: 'error',
                  message: res.message,
                }));
              }

              submit1.disabled = false;
              submit1.textContent = i18next.t('Invest');
            }
          } catch (error) {
            console.error("Error : ", error);
          }
        }
        resetAlertData();
      }
    }
  });

  const [File, setFile] = useState();
  const [FileName, setFileName] = useState('');
  const saveFile = (e) => {
    if((e.target.files).length > 0) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);

      var validationRes = checkFileValidation(e.target.files[0]);
      if (!validationRes.status) {
        setFile();
        setFileName('');

        setAlertData((AlertData) => ({
          ...AlertData,
          variant: 'error',
          message: validationRes.msg,
        }));
        resetAlertData();

        return false;
      }
    }
  };

  const validation2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      transfer_euro: 0,
      transaction_information: '',
      conditions: false,
    },
    validationSchema: Yup.object({
      transaction_information: Yup.string()
        .matches(
          /^[#0-9a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Property_Detail PG Transaction Information is required')),
        conditions: Yup.boolean()
      .oneOf([true], i18next.t('You must accept the conditions of the whitepaper and the personal loan agreement to continue!'))
    }),
    onSubmit: async (values) => {
      try {
        if (validation1.values.number_of_tokens === '' || validation1.values.number_of_tokens === 0) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('Property_Detail PG Number of Tokens is required'),
          }));
        }
        else if (parseFloat(YourInvestment) < parseFloat(PropertyInfo.minimum_investment_allocation)) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('Property_Detail PG Minimum investment Allocation is required'),
          }));
        }
        else if (FileName === '') {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('Property_Detail PG Please select a transaction proof!'),
          }));
        }
        else {
          let submit1 = document.getElementById('submit2');
          submit1.disabled = true;
          submit1.textContent = i18next.t('Processing...');

          const formData = new FormData();
          formData.append("p_id", 1);
          formData.append("currency", validation1.values.currency);
          formData.append("number_of_tokens", validation1.values.number_of_tokens);
          formData.append("is_used_euro_wallet", validation1.values.is_used_euro_wallet);
          formData.append("transfer_euro", values.transfer_euro);
          formData.append("transaction_information", values.transaction_information);
          formData.append("file", File);
          formData.append("fileName", FileName);
          const res = await postRequestByFormData('property-invest-by-bank', formData);
          if (res && res.code === 200) {
            validation1.setValues({
              ...validation1.values,
              currency: 'Euro',
              number_of_tokens: '',
              is_used_euro_wallet: false,
            });
            validation2.setValues({
              ...validation2.values,
              transfer_euro: 0,
              transaction_information: '',
              conditions: false,
            });
            setFile(null);
            setFileName('');

            setYourInvestment(0);
            setShareOfTotalEquity(0);
            handleLoadGetBinanceRates();
            handleLoadGetCryptoData();
            handleLoadGetTransactionData();
            handleLoadGetPropertyCryptoTransactionData();
            setTimeout(() => {
              handleClickCryptoBalanceRefresh();
            }, 7000);

            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'success',
              message: res.message,
            }));
          }
          else {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'error',
              message: res.message,
            }));
          }
          resetAlertData();

          submit1.disabled = false;
          submit1.textContent = i18next.t('Submit');

          setStandardModal1(false);
        }
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const handleSubmit4 = async () => {
    let submit1 = document.getElementById('submit4');
    submit1.disabled = true;
    submit1.textContent = i18next.t('Processing...');

    const formData = new FormData();
    formData.append("p_id", 1);
    formData.append("currency", validation1.values.currency);
    formData.append("number_of_tokens", validation1.values.number_of_tokens);
    formData.append("is_used_euro_wallet", validation1.values.is_used_euro_wallet);
    formData.append("transfer_euro", 0);
    formData.append("transaction_information", 'Used Euro Wallet Balance');
    const res = await postRequestByFormData('property-invest-by-bank', formData);
    if (res && res.code === 200) {
      validation1.setValues({
        ...validation1.values,
        currency: 'Euro',
        number_of_tokens: '',
        is_used_euro_wallet: false,
      });
      validation2.setValues({
        ...validation2.values,
        transfer_euro: 0,
        transaction_information: '',
        conditions: false,
      });
      setFile(null);
      setFileName('');

      setYourInvestment(0);
      setShareOfTotalEquity(0);
      handleLoadGetBinanceRates();
      handleLoadGetCryptoData();
      handleLoadGetTransactionData();
      handleLoadGetPropertyCryptoTransactionData();
      setTimeout(() => {
        handleClickCryptoBalanceRefresh();
      }, 7000);

      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'success',
        message: res.message,
      }));
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();

    submit1.disabled = false;
    submit1.textContent = i18next.t('Invest');

    setWrongModalOpen(false);
  }

  const [BinanceRatesData, setBinanceRatesData] = React.useState([]);
  const handleLoadGetBinanceRates = async () => {
    const res = await getRequestForBinance();
    if (res && res.code === 200) {
      setBinanceRatesData(res.data);
    }
  }

  const [PropertyInfo, setPropertyInfo] = React.useState([]);
  const [PropertyImages, setPropertyImages] = React.useState([]);
  const [documentLinks, setDocumentLinks] = useState([]);

  const handleLoadGetPropertyInfo = async () => {
    const jsonpar = JSON.stringify({
        p_id: 1
    });
    const res = await postRequest('get-property-info', jsonpar);
    if (res && res.code === 200) {
        setPropertyInfo(res.data.property);

        const filePath = res.data.url;
        const images = res.data.images;
        const documentPath = res.data.document_url;
        const documents = res.data.documents;

        const documentLinksArray = documents.map(doc => ({
            url: `${process.env.REACT_APP_API_URL}${documentPath}${doc}`
        }));
        setDocumentLinks(documentLinksArray);

        let tempArr = [];
        images.forEach(element => {
            const temp = {
                original: `${process.env.REACT_APP_API_URL}${filePath}${element.image}`,
                thumbnail: `${process.env.REACT_APP_API_URL}${filePath}${element.image}`,
            };
            tempArr.push(temp);
        });
        setPropertyImages(tempArr);
    } else {
        setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
        }));
    }
    resetAlertData();
};
  const [CryptoData, setCryptoData] = React.useState([]);
  const handleLoadGetCryptoData = async () => {
    const res = await getRequest('get-crypto-data');
    if (res && res.code === 200) {
      setCryptoData(res.data);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const handleClickCryptoBalanceRefresh = async () => {
    const res = await getRequest('crypto-data-refresh');
    if (res && res.code === 200) {
      setCryptoData(res.data);
    }
  }

  const [TransactionData, setTransactionData] = React.useState([]);
  const handleLoadGetTransactionData = async () => {
    const jsonpar = JSON.stringify({
      currency_type: 'Euro',
    });
    const res = await postRequest('get-withdrawal-transaction-data', jsonpar);
    if (res && res.code === 200) {
      setTransactionData(res.data);
    }
  }
  const [PropertyCryptoTransactionData, setPropertyCryptoTransactionData] = React.useState([]);
  const handleLoadGetPropertyCryptoTransactionData = async () => {
    const res = await getRequest('get-property-crypto-transaction-data');
    if (res && res.code === 200) {
      setPropertyCryptoTransactionData(res.data.transaction);
    }
  }
  const [SiteSettings, setSiteSettings] = React.useState([]);
  const handleLoadGetSiteSettings = async () => {
    const res = await getRequest('get-site-settings');
    if (res && res.code === 200) {
      setSiteSettings(res.data);
    }
  }
  React.useEffect(() => {
    handleLoadGetBinanceRates();
    handleLoadGetPropertyInfo();
    handleLoadGetCryptoData();
    handleLoadGetTransactionData();
    handleLoadGetPropertyCryptoTransactionData();
    handleLoadGetSiteSettings();
    
    validation1.setValues({ ...validation1.values, currency: 'Euro' });
    handleChangeNumberOfTokens('', 'Euro');
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid className="PropertyDetails">
          <Row className="mt-2">
            <Col className="col-md-12">
              <div className="fullWidthLink">
                <Link className="back-link" to="/dashboard"><i className="bx bx-left-arrow-alt"></i> {i18next.t('Back')}</Link>
              </div>
              <h5 className="mb-3 mainTitle">{PropertyInfo.property_name}</h5>
              <div className="propert-short-des" dangerouslySetInnerHTML={{ __html: PropertyInfo.property_description }} />
              <p className="location"><i className="bx bx-map-pin"></i> <span>{PropertyInfo.property_address}</span></p>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-12">
              <div className="leftBar">
                <Card className="mb-0">
                  <CardBody>
                    <div className="product-detai-imgs">
                      <Row>
                        <div className="wrapper">
                          <ImageGallery thumbnailPosition="left" items={PropertyImages} />
                        </div>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="RightBar">
                <Card className="light-green zoom-in-out-box">
                  <CardBody>
                    <div className="product-detai-imgs">
                      <div className="clearfix"></div>
                      <p className="increments-text">{i18next.t('Property_Detail PG Type a number of tokens you want')}</p>
                      <div className="clearfix"></div>
                      <div className="AvailableBox fullWidth">
                        <div className="mb-3 right-selectbox">
                          <div className="leftBox Cstm-fullWidth">
                            <select className="form-select" name="currency" value={validation1.values.currency}
                              onChange={(e) => {
                                validation1.setValues({ ...validation1.values, currency: e.target.value });
                                handleChangeNumberOfTokens(validation1.values.number_of_tokens, e.target.value);
                              }}
                            >
                              <option value="">{i18next.t('Select currency')}</option>
                              {/* <option value="BTC">BTC</option>
                              <option value="ETH">ETH</option>
                              <option value="MATIC">MATIC</option>
                              <option value="USDT">USDT-ERC20</option>
                              <option value="USDC">USDC-ERC20</option> */}
                              <option value="Euro">{i18next.t('Euro Bank Transfer')}</option>
                            </select>
                            {validation1.touched.currency && validation1.errors.currency ? (
                              <FormFeedback type="invalid">{validation1.errors.currency}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="rightBox Cstm-fullWidth">
                            <Input
                              name="number_of_tokens"
                              type="text"
                              placeholder={i18next.t('Enter number of tokens')}
                              onChange={(e) => {
                                validation1.handleChange(e);
                                handleChangeNumberOfTokens(e.target.value, validation1.values.currency);
                              }}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.number_of_tokens || ""}
                              invalid={
                                validation1.touched.number_of_tokens && validation1.errors.number_of_tokens ? true : false
                              }
                            />
                            {validation1.touched.number_of_tokens && validation1.errors.number_of_tokens ? (
                              <FormFeedback type="invalid">{validation1.errors.number_of_tokens}</FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        {/* {validation1.values.currency === 'Euro'? */}
                        <div className="form-check mb-3">
                          <input type="checkbox" className="form-check-input" id="is_used_euro_wallet" name="is_used_euro_wallet" checked={validation1.values.is_used_euro_wallet || false} onClick={(e) => handleClickCheckbox(true, validation1.values.number_of_tokens)}/>
                          <label className="form-check-label" htmlFor="is_used_euro_wallet"> Euro {i18next.t('Wallet Balance')}: <b>€{CryptoData.euro_available_balance}</b> </label>
                        </div>
                        {/* : ''} */}
                      </div>
                      {validation1.values.currency !== 'Euro'?
                        <Button
                          id="submit1"
                          onClick={(e) => {
                            e.preventDefault();
                            validation1.handleSubmit(false);
                            return false;
                          }}
                          color="primary" type="button" className="ExpressInterest">{i18next.t('Invest')}</Button>
                      :
                        <Button color="primary" type="button" className="ExpressInterest" 
                          id="submit3"
                          onClick={(e) => {
                            e.preventDefault();
                            validation1.handleSubmit(true);
                            return false;
                          }}>{i18next.t('Invest')}</Button>
                      }
                      <div className="total-investment">
                        {validation1.values.currency !== '' ?
                          <>
                            <p className="total-purchase-text">{i18next.t('Your investment')}</p>
                            <p className="total-purchase">{YourInvestment} {validation1.values.currency}</p>
                          </>
                          : ''}
                      </div>
                      <p className="ShareAllocation">{i18next.t('Property_Detail PG Minimum investment Allocation')} : <b>{PropertyInfo.minimum_investment_allocation} EUR</b></p>
                      <p className="ShareAllocation">{i18next.t('Property_Detail PG Available number of Tokens')} : <b>{PropertyInfo.total_token}</b></p>
                      <p className="ShareAllocation">{i18next.t('Property_Detail PG Share of total equity')} : <b>{ShareOfTotalEquity}%</b></p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container >

        <div className="clearfix"></div>

        <Container fluid>
          <div className="contentt fullWidth">
            <Card>
              <CardBody>
                <h5 className="property-inner-title">{i18next.t('Property Description')} :</h5>
                <p className="common-des">{i18next.t('Description details line 1')}</p>
                <p className="common-des">{i18next.t('Description details line 2')}</p>

                <div className="row mt-4">
                  <Col className="col-md-12">
                    <p className="phse_title">{i18next.t('Features')}:</p>
                  </Col>
                  <Col className="col-12 mb-4">
                    <p className="features_title">{i18next.t('Feature1')}</p>
                    <Row>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Feature2')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Feature3')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Feature4')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Feature5')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Feature6')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Feature7')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Feature8')}</span></p>
                      </Col>                      
                    </Row>
                                      
                  </Col>

                  <Col className="col-12 mb-4">
                    <p className="features_title">{i18next.t('Common spaces')}</p>
                    <Row>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space1')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space2')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space3')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space4')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space5')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space6')}</span></p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space7')}</span></p>
                      </Col> 
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space8')}</span></p>
                      </Col> 
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space9')}</span></p>
                      </Col>    
                      <Col lg={3} md={6} sm={12}>
                        <p className="Check-amenities common-des"><i className='bx bx-check'></i> <span>{i18next.t('Common space10')}</span></p>
                      </Col>                   
                    </Row>                                      
                  </Col>

                  <Col className="col-12">
                    <p className="about_sort-des common-des">{i18next.t('Investment desc')}</p>
                  </Col>
                  <Col className="col-12 mt-3 mb-3">
                    <p className="features_title">{i18next.t('Investment')}</p>
                    <div className="investment_details">
                      <ul>
                        <li className="common-des"><span>{i18next.t('Purpose of the loan')}:</span> {i18next.t('Purpose of the loan desc')}</li>
                        <li className="common-des"><span>{i18next.t('Typology')}:</span> {i18next.t('Fixed-rate loan')}</li>
                        <li className="common-des"><span>{i18next.t('Modality')}:</span> {i18next.t('Bullet Type')}</li>
                        <li className="common-des"><span>{i18next.t('Guarantee')}:</span> {i18next.t('Second Rank Mortgage')}</li>
                        <li className="common-des"><span>{i18next.t('Term')}:</span> {i18next.t('14 months (+4 months possible extension)')}</li>
                        <li className="common-des"><span>{i18next.t('Interest rate')}:</span> {i18next.t('interest perc')}</li>
                        <li className="common-des"><span>{i18next.t('Profitability')}:</span> {i18next.t('Profitability perc')}</li>
                        <li className="common-des"><span>{i18next.t('Interest Payment')}:</span> {i18next.t('Interest Payment desc')}</li>
                        <li className="common-des"><span>{i18next.t('Contributions')}:</span></li>
                        <ul className="investment_details_sub">
                          <li className="common-des"><span>{i18next.t('Developer')}:</span> {i18next.t('1.850.000 €')}</li>
                          <li className="common-des"><span>{i18next.t('Bank Entity')}:</span> {i18next.t('4.150.000 €')}</li>
                          <li className="common-des"><span>{i18next.t('Tokenized Green Loan')}:</span>  {i18next.t('135.250 €')}</li>
                        </ul>
                      </ul>
                    </div>                   
                  </Col>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h5 className="property-inner-title">{i18next.t('Impact')} :</h5>
                <p className="common-des">{i18next.t('Impact Desc1')}</p>
                <p className="common-des">{i18next.t('Impact Desc2')}</p>
                <p className="common-des">{i18next.t('Impact Desc3')}</p>
                <p className="common-des">{i18next.t('Impact Desc4')}</p>
                <p className="common-des">{i18next.t('Impact Desc5')}:</p>
                {documentLinks.length > 0 && (
                <>
                    {documentLinks[0] && (
                        <div className="fileBox mt-2">
                            <i className="bx bx-file"></i>
                            <a href={documentLinks[5].url} target="_blank" rel="noopener noreferrer">
                                <span>{i18next.t('Impact Sheet')}</span>
                            </a>
                        </div>
                    )}
                </>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h5 className="property-inner-title">{i18next.t('Developer')} :</h5>
                <p className="common-des comapny-name">{i18next.t('Valgreen Ecovivienda S.L')}</p>
                <div className="comapny-logo">
                  <img src={volgreen} />
                </div>
                <p className="common-des">{i18next.t('Developer Desc')}</p>

                <div className="row">                  
                  <Col className="col-12 mb-3">   
                    <p className="phse_title">{i18next.t('Trajectory')}:</p>              
                    <div className="table-responsive">
                      <table className="table mb-0">                        
                        <tbody>
                          <tr>
                            <th style={{width: "300px"}}>{i18next.t('Active since')}</th>
                            <td>2018</td>                           
                          </tr>
                          <tr>
                            <th style={{width: "300px"}}>{i18next.t('Fiscal Country')}</th>
                            <td>{i18next.t('Spain')}</td>                           
                          </tr>
                          <tr>
                            <th style={{width: "300px"}}>{i18next.t('Geographical Operability')}</th>
                            <td>{i18next.t('Barcelona')}</td>                           
                          </tr>
                          <tr>
                            <th style={{width: "300px"}}>{i18next.t('Collaborations with Tokenized Green')}</th>
                            <td>1</td>                           
                          </tr>                          
                        </tbody>
                      </table>
                    </div>                  
                  </Col>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h5 className="property-inner-title">{i18next.t('Team')} :</h5>
                
                <div className="row">   

                  <Col className="col-lg-2 col-md-4 col-sm-6 col-12">   
                    <div className="card">
                      <div className="card-body">
                        <div className="text-box">
                          <img src={Jose1} alt="" className="team-img" />
                          <div className="team-info">
                            <p className="team-name">{i18next.t('José Valverde')}</p>
                            <p className="team-position">{i18next.t('CEO & Founder')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col className="col-lg-2 col-md-4 col-sm-6 col-12">   
                    <div className="card">
                      <div className="card-body">
                        <div className="text-box">
                          <img src={Alexandre} alt="" className="team-img" />
                          <div className="team-info">
                            <p className="team-name">{i18next.t('Alexandre Lupion')}</p>
                            <p className="team-position">{i18next.t('Co-Founder')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col className="col-lg-2 col-md-4 col-sm-6 col-12">   
                    <div className="card">
                      <div className="card-body">
                        <div className="text-box">
                          <img src={Josep} alt="" className="team-img" />
                          <div className="team-info">
                            <p className="team-name">{i18next.t('Josep Buyesc')}</p>
                            <p className="team-position">{i18next.t('Sustainability Architect')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col className="col-lg-2 col-md-4 col-sm-6 col-12">   
                    <div className="card">
                      <div className="card-body">
                        <div className="text-box">
                          <img src={Ramon} alt="" className="team-img" />
                          <div className="team-info">
                            <p className="team-name">{i18next.t('Albert Ripoll')}</p>
                            <p className="team-position">{i18next.t('Construction Manager')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col className="col-lg-2 col-md-4 col-sm-6 col-12">    
                    <div className="card">
                      <div className="card-body">
                        <div className="text-box">
                          <img src={Jose2} alt="" className="team-img" />
                          <div className="team-info">
                            <p className="team-name">{i18next.t('Álvaro Ramos')}</p>
                            <p className="team-position">{i18next.t('Project Manager')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col className="col-lg-2 col-md-4 col-sm-6 col-12">   
                    <div className="">
                      <div className="">
                        <div className="common-links">
                          <Link><i className="bx bxl-instagram"></i><span>{i18next.t('valgreen')}</span></Link>
                          <Link><i className="bx bx-globe"></i><span>{i18next.t('valgreenEs')}</span></Link>
                        </div>
                      </div>
                    </div>
                  </Col>

                </div>
              </CardBody>
            </Card>


            <Card>
              <CardBody>
                <div className="mt-3">
                  <h5 className="property-inner-title mb-3">{i18next.t('Location')} :</h5>
                </div>
                <iframe title="Google Map" className="googleMap" loading="lazy" allowFullScreen src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2983.765655116769!2d2.4073345!3d41.5959522!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4cbe841e48509%3A0xb2ef304dae952d28!2sCarrer%20Pau%20Picasso%2C%2031%2C%20Bajos%20D%2C%2008319%20Dosrius%2C%20Barcelona%2C%20Spain!5e0!3m2!1sen!2sin!4v1717156338322!5m2!1sen!2sin" style={{ border: 0 }} aria-hidden="false" tabIndex="0"></iframe>
              </CardBody>
            </Card>

<CardBody>
    <h5 className="property-inner-title mb-4">{i18next.t('Documents')} :</h5>
    <div className="row mb-4">
        <Col className="col-12 mb-3">
            {documentLinks.length > 0 && (
                <>
                    {documentLinks[0] && (
                        <div className="fileBox mt-2">
                            <i className="bx bx-file"></i>
                            <a href={documentLinks[0].url} target="_blank" rel="noopener noreferrer">
                                <span>{i18next.t('Catastro')}</span>
                            </a>
                        </div>
                    )}
                    {documentLinks[1] && (
                        <div className="fileBox mt-2">
                            <i className="bx bx-file"></i>
                            <a href={documentLinks[1].url} target="_blank" rel="noopener noreferrer">
                                <span>{i18next.t('Fich Resumen Economico')}</span>
                            </a>
                        </div>
                    )}
                    {documentLinks[2] && (
                        <div className="fileBox mt-2">
                            <i className="bx bx-file"></i>
                            <a href={documentLinks[2].url} target="_blank" rel="noopener noreferrer">
                                <span>{i18next.t('Memoria de calidades')}</span>
                            </a>
                        </div>
                    )}
                    {documentLinks[3] && (
                        <div className="fileBox mt-2">
                            <i className="bx bx-file"></i>
                            <a href={documentLinks[3].url} target="_blank" rel="noopener noreferrer">
                                <span>{i18next.t('Planos')}</span>
                            </a>
                        </div>
                    )}
                    {documentLinks[4] && (
                        <div className="fileBox mt-2">
                            <i className="bx bx-file"></i>
                            <a href={documentLinks[4].url} target="_blank" rel="noopener noreferrer">
                                <span>{i18next.t('Whitepaper')}</span>
                            </a>
                        </div>
                    )}
                </>
            )}
        </Col>
    </div>
</CardBody>


            {/* <Card>
              <CardBody>
                <h5 className="property-inner-title mb-4">{i18next.t('Documents')} :</h5>
                <div className="row mb-4">                  
                  <Col className="col-12 mb-3">                 
                    <div className="fileBox mt-2">
                      <i className="bx bx-file"></i>
                      <span>{i18next.t('Impact Sheet1')}</span>
                    </div> 
                    <div className="fileBox mt-2">
                      <i className="bx bx-file"></i>
                      <span>{i18next.t('Financial Resume')}</span>
                    </div> 
                    <div className="fileBox mt-2">
                      <i className="bx bx-file"></i>
                      <span>{i18next.t('Property Dossier')}</span>
                    </div>                   
                  </Col>
                </div>
              </CardBody>
            </Card> */}


          </div>
        </Container>


        <Modal isOpen={StandardModal1} backdrop={'static'} centered className="transation" size="xl">
          <div className="modal-header">
            <h5 className="modal-title mt-0">{i18next.t('Property_Detail PG Upload Transaction Information')}</h5>
            <button type="button" onClick={(e) => setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body row">
            <div className="col-lg-6">
              <div className="bgBankInfo">
                <p className="ShareAllocation ShareAllocation-details mb-3">{i18next.t('Property_Detail PG Please transfer')} <b>{validation2.values.transfer_euro} EUR</b> {i18next.t('Property_Detail PG to below account')}</p>
                <p className="accountInfoDetails"><b>{i18next.t('Beneficiary Name')} :</b> {SiteSettings.beneficiary_name}</p>
                <p className="accountInfoDetails"><b>{i18next.t('Bank Name')} :</b> {SiteSettings.bank_name}</p>
                <p className="accountInfoDetails"><b>{i18next.t('IBAN')} :</b> {SiteSettings.iban_no}</p>
                {/* <p className="accountInfoDetails"><b>{i18next.t('Routing Number')} :</b> {SiteSettings.routing_number}</p> */}
                <p className="accountInfoDetails"><b>{i18next.t('SWIFT Code')} :</b> {SiteSettings.swift_code}</p>
                <p className="accountInfoDetails"><b>{i18next.t('Send us proof of payment with concept')} :</b> {i18next.t('Valgreen Project')} - {validation1.values.number_of_tokens} Tokens </p>

                <div className="uploaddocument">
                  <CopyToClipboard text={`${i18next.t('Beneficiary Name')} : ${SiteSettings.beneficiary_name}
${i18next.t('Bank Name')} : ${SiteSettings.bank_name}
${i18next.t('IBAN')} : ${SiteSettings.iban_no}
${i18next.t('SWIFT Code')} : ${SiteSettings.swift_code}`}>
                    <Button color="primary" className="ExpressInterest btn btn-light">{i18next.t('Property_Detail PG Copy Bank Details')}</Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bgBankInfo bg-white">
                <div className="mb-3">
                  <Label className="form-label">{i18next.t('Property_Detail PG Transaction Information')}</Label>
                  <Input type="text"
                    name="transaction_information"
                    placeholder={i18next.t('Property_Detail PG Please Enter Transaction Information')}
                    onChange={validation2.handleChange}
                    onBlur={validation2.handleBlur}
                    value={validation2.values.transaction_information || ""}
                  />
                  {validation2.touched.transaction_information && validation2.errors.transaction_information ? (
                    <FormFeedback type="invalid">{validation2.errors.transaction_information}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{i18next.t('Property_Detail PG Upload Transaction Proof')}</Label>
                  <Input type="file" onChange={saveFile}/>
                </div>

                <div className="form-check mb-3 upload-documents-chk">
                  <input type="checkbox" className="form-check-input" id="customControlInline" name="conditions" checked={validation2.values.conditions || false} onClick={(e) => validation2.setValues({ ...validation2.values, conditions: !validation2.values.conditions })} />
                  <label className="form-check-label" htmlFor="customControlInline">  {i18next.t('Whitepaper and loan aggrement condition')} </label>
                  {validation2.touched.conditions && validation2.errors.conditions ? (
                    <FormFeedback type="invalid">{validation2.errors.conditions}</FormFeedback>
                  ) : null}
                </div>

                <Button color="primary" className="ExpressInterest mt-0"
                  id="submit2"
                  onClick={(e) => {
                    e.preventDefault();
                    validation2.handleSubmit();
                    return false;
                  }}
                >{i18next.t('Submit')}</Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal isOpen={WrongModalOpen} toggle={() => setWrongModalOpen(false)} centered>
          <div className="modal-header">
            <button type="button" onClick={() => setWrongModalOpen(false)} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <i className="fas fa-exclamation-triangle fa-3x text-warning"></i>
            <p className="mt-3">{i18next.t('Are you sure you want to submit?')}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setWrongModalOpen(false)}>
              {i18next.t('Close')}
            </button>
            <button type="button" className="btn btn-primary" 
              id="submit4"
              onClick={(e) => {
                handleSubmit4(e);
              }}>
              {i18next.t('Submit')}
            </button>
          </div>
        </Modal>
      </div >
    </React.Fragment >
  )
}

PropertyDetails.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
}

export default PropertyDetails
