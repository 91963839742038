import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  FormFeedback,
  Modal,
  Button,
} from 'reactstrap'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { Link, useLocation, useHistory } from 'react-router-dom'

// import images
import profileImg from '../../assets/images/profile-img.png'
import logo from 'assets/images/logo-large.png'

import '../../assets/scss/custom/style.css'

import Snackbars from '../../Utils/Snackbars'
import useRequestsWOAccessToken from '../../Utils/useRequestsWOAccessToken'
import i18next from 'i18next'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Box from '@material-ui/core/Box'
import { TextField } from '@material-ui/core'

const MobileVerification = props => {
  //meta title
  document.title = i18next.t('Email_Verification PG document title')
  const location = useLocation()
  const history = useHistory()
  const { getRequestWOAccessToken, postRequestWOAccessToken } =
    useRequestsWOAccessToken()

  const [EmailPhone, setEmailPhone] = useState('email')
  const [modal_standard, setmodal_standard] = useState(false)
  function tog_standard() {
    setEmailPhone('email')
    setmodal_standard(!modal_standard)
  }
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [modal_standard1, setmodal_standard1] = useState(false)
  function tog_standard1() {
    setEmailPhone('phone')
    setmodal_standard1(!modal_standard1)
  }

  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  })
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData(AlertData => ({
        ...AlertData,
        variant: '',
        message: '',
      }))
    }, 5000)
  }

  const [formData1, setFormData1] = React.useState({
    country_code: { id: 212, title: 'ES +34', code: 'ES', value: 34 },
  })
  const [CustomError1, setCustomError1] = React.useState({
    country_code: '',
  })
  const [AllCountriesCustomData, setAllCountriesCustomData] = React.useState([])
  const handleLoadGetAllCountries = async () => {
    const res = await getRequestWOAccessToken('get-all-countries')
    if (res && res.code === 200) {
      let originalText = res.data

      let tempArr = []
      originalText.forEach(element => {
        let temp = {
          id: element.id,
          title: element.country_code + ' +' + element.phone_code,
          code: element.country_code,
          value: element.phone_code,
        }
        tempArr.push(temp)
      })
      setAllCountriesCustomData(tempArr)

      if (
        typeof location.state !== 'undefined' &&
        typeof location.state.message !== 'undefined'
      ) {
        let temp_country_code = originalText.find(
          element => element.phone_code === location.state.country_code,
        )
        setFormData1({
          ...formData1,
          country_code: {
            id: temp_country_code.id,
            title: `${temp_country_code.country_code} +${temp_country_code.phone_code}`,
            code: temp_country_code.country_code,
            value: temp_country_code.phone_code,
          },
        })
      }
    } else {
      setAlertData(AlertData => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }))
    }
    resetAlertData()
  }

  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      email_otp: '',
      phone_otp: '',
    },
    validationSchema: Yup.object({
      email_otp: Yup.string()
        .matches(/^[#0-9]+$/, i18next.t('Please enter a valid value!'))
        .required(i18next.t('Email_Verification PG Email OTP is required'))
        .min(
          6,
          i18next.t('Email_Verification PG Email OTP should be 6 digits!'),
        )
        .max(
          6,
          i18next.t('Email_Verification PG Email OTP should be 6 digits!'),
        ),
      phone_otp: Yup.string()
        .matches(/^[#0-9]+$/, i18next.t('Please enter a valid value!'))
        .required(i18next.t('Email_Verification PG Phone OTP is required'))
        .min(
          6,
          i18next.t('Email_Verification PG Phone OTP should be 6 digits!'),
        )
        .max(
          6,
          i18next.t('Email_Verification PG Phone OTP should be 6 digits!'),
        ),
    }),
    onSubmit: async values => {
      try {
        let submit1 = document.getElementById('submit1')
        submit1.disabled = true
        submit1.textContent = i18next.t('Processing...')

        const jsonpar = JSON.stringify({
          u_id: UID,
          email: Email,
          email_otp: values.email_otp,
          phone_otp: values.phone_otp,
        })
        const res = await postRequestWOAccessToken('verify-otp', jsonpar)
        if (res && res.code === 200) {
          history.push('/login', {
            code: 200,
            status: 'success',
            message: res.message,
          })
        } else {
          setAlertData(AlertData => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }))
        }
        resetAlertData()

        submit1.disabled = false
        submit1.textContent = i18next.t('Submit')
      } catch (error) {
        console.error('Error : ', error)
      }
    },
  })

  const resendEmailOTP = async (alertMSG = 0) => {
    const jsonpar = JSON.stringify({
      u_id: UID,
    })
    const res = await postRequestWOAccessToken('send-otp', jsonpar)
    if (res && res.code === 200) {
      setAlertData(AlertData => ({
        ...AlertData,
        variant: 'success',
        message: alertMSG === 1 ? i18next.t('Email_Verification PG email updated and sent OTP') : res.message,
      }))
    } else {
      setAlertData(AlertData => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }))
    }
    resetAlertData()
  }

  const resendPhoneOTP = async (alertMSG = 0) => {
    const jsonpar = JSON.stringify({
      u_id: UID,
    })
    const res = await postRequestWOAccessToken('send-phone-otp', jsonpar)
    if (res && res.code === 200) {
      setAlertData(AlertData => ({
        ...AlertData,
        variant: 'success',
        message: alertMSG === 1 ? i18next.t('Email_Verification PG phone updated and sent OTP') : res.message,
      }))
    } else {
      setAlertData(AlertData => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }))
    }
    resetAlertData()
  }

  const validation2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      phone: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(i18next.t('Please enter a valid value!'))
        .max(255)
        .required(i18next.t('Register PG Email is required')),
      phone: Yup.string()
        .matches(/^[#0-9]+$/, i18next.t('Please enter a valid value!'))
        .required(i18next.t('Register PG Mobile Number is required'))
        .test(
          'len',
          i18next.t(
            'Register PG Mobile numbers should be 9 digits long for Spain and 10 digits long for other countries!',
          ),
          function (value) {
            const country_code = parseInt(formData1.country_code.value)
            const length = value ? value.length : 0
            if (country_code === 34) {
              return length === 9 // For country code 34, phone number should be exactly 9 digits
            } else {
              return length === 10 // For other country codes, phone number should be exactly 10 digits
            }
          },
        ),
    }),
    onSubmit: async values => {
      try {
        setCustomError1({
          ...CustomError1,
          country_code: '',
        })

        if (formData1.country_code === null) {
          setCustomError1({
            ...CustomError1,
            country_code: i18next.t('Register PG Please select country code'),
          })
        } else {
          let submit2 = document.getElementById('submit2')
          let submit3 = document.getElementById('submit3')
          if(EmailPhone === 'email') {
            submit2.disabled = true
            submit2.textContent = i18next.t('Processing...')
          } else {
            submit3.disabled = true
            submit3.textContent = i18next.t('Processing...')
          }

          const jsonpar = JSON.stringify({
            u_id: UID,
            email: values.email,
            country_code: formData1.country_code.value,
            phone: values.phone
          })
          const res = await postRequestWOAccessToken(
            'update-email-phone',
            jsonpar,
          )
          if (res && res.code === 200) {
            if(EmailPhone === 'email') {
              resendEmailOTP(1)
            }
            else {
              resendPhoneOTP(1)
            }
            setEmail(values.email)
            SetCountryCode(formData1.country_code.value)
            setPhone(values.phone)

            setmodal_standard(false);
            setmodal_standard1(false);
          } else {
            setAlertData(AlertData => ({
              ...AlertData,
              variant: 'error',
              message: res.message,
            }))
          }
          resetAlertData()

          if(EmailPhone === 'email') {
            submit2.disabled = false
            submit2.textContent = i18next.t('Update')
          }
          else {
            submit3.disabled = false
            submit3.textContent = i18next.t('Update')
          }
        }
      } catch (error) {
        console.error('Error : ', error)
      }
    },
  })

  const [UID, setUID] = React.useState(0)
  const [Email, setEmail] = React.useState('')
  const [CountryCode, SetCountryCode] = React.useState('')
  const [Phone, setPhone] = React.useState('')
  React.useEffect(() => {
    handleLoadGetAllCountries()

    if (
      typeof location.state !== 'undefined' &&
      typeof location.state.message !== 'undefined'
    ) {
      validation2.setValues({
        email: location.state.email,
        phone: location.state.phone,
      })

      setUID(location.state.id)
      setEmail(location.state.email)
      SetCountryCode(location.state.country_code)
      setPhone(location.state.phone)
      setAlertData(AlertData => ({
        ...AlertData,
        variant: location.state.status,
        message: location.state.message,
      }))
      resetAlertData()
    }
  }, [])

  return (
    <React.Fragment>
      {AlertData.message !== '' ? (
        <Snackbars variant={AlertData.variant} message={AlertData.message} />
      ) : (
        ''
      )}

      <div className='p-0 container-fluid'>
        <Row className='g-0'>
          <Col className='LeftImg'>
            <div className='auth-full-page-content p-4 bg-white'>
              <div className='w-100'>
                <div className='d-flex flex-column h-100'>
                  <div className='mb-4 mb-md-5'>
                    <Link to='/' className='auth-logo-light'>
                      <div className='mb-1'>
                        <span className=''>
                          {' '}
                          <img src={logo} alt='' height='60' />{' '}
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className='my-auto'>
                    <div>
                      <h5 className='text-black mb-3'>
                        {i18next.t(
                          'Email_Verification PG Email Address and Phone Number Verification',
                        )}
                      </h5>
                    </div>
                    <p className='text-black mb-4'>
                      {i18next.t(
                        'Email_Verification PG An OTP has been sent to your registered',
                      )}{' '}
                      <br />
                    </p>

                    <div className='mt-4'>
                      <Col md={12}>
                        <div className='mb-1'>
                          <Label className='form-label'>
                            {i18next.t('Register PG Email')} : {Email}
                            <i
                              className='bx bx-pencil edit_icon'
                              onClick={(e) => tog_standard()}
                            ></i>
                          </Label>
                        </div>
                        <div className='mb-3'>
                          <Label className='form-label'>
                            {i18next.t('Register PG Mobile no')} : +{CountryCode} {Phone}
                            <i
                              className='bx bx-pencil edit_icon'
                              onClick={(e) => tog_standard1()}
                            ></i>
                          </Label>
                        </div>
                      </Col>
                      <form>
                        <div>
                          <Col md='12' xs='12'>
                            <div className='mb-4'>
                              <Label className='form-label'>{i18next.t('Email_Verification PG Email OTP')} :</Label>
                              <Input
                                name='email_otp'
                                type='text'
                                placeholder={i18next.t(
                                  'Email_Verification PG Enter Email OTP',
                                )}
                                onChange={validation1.handleChange}
                                onBlur={validation1.handleBlur}
                                value={validation1.values.email_otp || ''}
                                invalid={
                                  validation1.touched.email_otp &&
                                  validation1.errors.email_otp
                                    ? true
                                    : false
                                }
                              />
                              {validation1.touched.email_otp &&
                              validation1.errors.email_otp ? (
                                <FormFeedback type='invalid'>
                                  {validation1.errors.email_otp}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className='mb-4'>
                              <Label className='form-label'>
                                {i18next.t('Email_Verification PG Phone OTP')} :
                              </Label>
                              <Input
                                name='phone_otp'
                                type='text'
                                placeholder={i18next.t(
                                  'Email_Verification PG Enter Phone OTP',
                                )}
                                onChange={validation1.handleChange}
                                onBlur={validation1.handleBlur}
                                value={validation1.values.phone_otp || ''}
                                invalid={
                                  validation1.touched.phone_otp &&
                                  validation1.errors.phone_otp
                                    ? true
                                    : false
                                }
                              />
                              {validation1.touched.phone_otp &&
                              validation1.errors.phone_otp ? (
                                <FormFeedback type='invalid'>
                                  {validation1.errors.phone_otp}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Button
                            id='submit1'
                            onClick={e => {
                              e.preventDefault()
                              validation1.handleSubmit()
                              return false
                            }}
                            className='btn btn-primary btn-block fullWidth'
                          >
                            {i18next.t('Submit')}
                          </Button>
                        </div>
                      </form>
                      <div className='mt-5 text-center'>
                        <p className='mb-2'>
                          <Link
                            to='#'
                            className='text-green'
                            onClick={(e) => resendEmailOTP()}
                          >
                            {i18next.t(
                              'Email_Verification PG Click here to email otp resend.',
                            )}
                          </Link>
                        </p>
                        <p className='mb-0'>
                          <Link
                            to='#'
                            className='text-green'
                            onClick={(e) => resendPhoneOTP()}
                          >
                            {i18next.t(
                              'Email_Verification PG Click here to phone otp resend.',
                            )}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4 mt-md-5 text-center'>
                    <p className='footer-text-color'>
                      {' '}
                      © {new Date().getFullYear()} Tokenizedgreen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className='rightImg'>
            <div className='bgLogin'></div>
          </Col>
        </Row>

        {/* Email Modal */}
        <Modal
          centered
          backdrop='static'
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className='modal-header'>
            <h5 className='modal-title mt-0'>{i18next.t('Email_Verification PG Change Email Address')}</h5>
            <button
              type='button'
              onClick={() => {
                setmodal_standard(false)
              }}
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <Col md={12}>
              <div className='mb-4'>
                <Label className="form-label">{i18next.t('Register PG Email')} :</Label>
                <Input
                  name="email"
                  type="email"
                  placeholder={i18next.t('Register PG Enter email')}
                  onChange={validation2.handleChange}
                  onBlur={validation2.handleBlur}
                  value={validation2.values.email || ""}
                  invalid={
                    validation2.touched.email && validation2.errors.email ? true : false
                  }
                />
                {validation2.touched.email && validation2.errors.email ? (
                  <FormFeedback type="invalid">{validation2.errors.email}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Button id="submit2"
              onClick={(e) => {
                e.preventDefault();
                validation2.handleSubmit();
                return false;
              }}
              className='btn btn-primary mb-2'>
              {i18next.t('Update')}
            </Button>
          </div>
        </Modal>

        {/* Phone Number Modal */}
        <Modal
          centered
          backdrop='static'
          isOpen={modal_standard1}
          toggle={() => {
            tog_standard1()
          }}
        >
          <div className='modal-header'>
            <h5 className='modal-title mt-0'>{i18next.t('Email_Verification PG Change Mobile Number')}</h5>
            <button
              type='button'
              onClick={() => {
                setmodal_standard1(false)
              }}
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <Col md={12}>
              <div className=''>
                <Col xs={12} className='fullWidth'>
                  <div className='mb-3 Register-Select-Code fullWidth'>
                    <Label>{i18next.t('Register PG Mobile no')} :</Label>
                    <div className='fullWidth'>
                      <Autocomplete
                        value={formData1.country_code}
                        onChange={(event, newValue) => {
                          setFormData1({ ...formData1, country_code: newValue })
                        }}
                        id='country-code'
                        options={AllCountriesCustomData}
                        getOptionLabel={option => option.title}
                        renderOption={option => (
                          <Box
                            component='li'
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          >
                            <img
                              loading='lazy'
                              width='20'
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt={option.code}
                            />
                            &nbsp;{option.title}
                          </Box>
                        )}
                        renderInput={params => (
                          <TextField {...params} variant='outlined' />
                        )}
                      />
                      {CustomError1.country_code !== '' ? (
                        <FormFeedback type='invalid' style={{ display: 'block' }}>
                          {CustomError1.country_code}
                        </FormFeedback>
                      ) : null}
                      <div className='cstm_mobile_number'>
                        <Input
                          name="phone"
                          type="number"
                          placeholder={i18next.t('Register PG Enter mobile number')}
                          onChange={validation2.handleChange}
                          onBlur={validation2.handleBlur}
                          value={validation2.values.phone || ""}
                          invalid={
                            validation2.touched.phone && validation2.errors.phone ? true : false
                          }
                        />
                        {validation2.touched.phone && validation2.errors.phone ? (
                          <FormFeedback type="invalid">{validation2.errors.phone}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Col>

            <Button id="submit3"
              onClick={(e) => {
                e.preventDefault();
                validation2.handleSubmit();
                return false;
              }} 
            className='btn btn-primary mb-2'>{i18next.t('Update')}</Button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default MobileVerification
