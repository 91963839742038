import React from "react";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, FormFeedback } from "reactstrap";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import "../../assets/scss/custom/style.css";

import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const Changepassword = () => {
  //meta title
  document.title = i18next.t('Change_Password PG document title');
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};


  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      old_password: '',
      new_password: '',
      new_confirm_password: '',
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required(i18next.t('Change_Password PG Old password is required')),
      new_password: Yup.string()
        .matches(/[A-Z]/, i18next.t('Change_Password PG Password must contain at least one uppercase letter'))
        .matches(/[a-z]/, i18next.t('Change_Password PG Password must contain at least one lowercase letter'))
        .matches(/\d/, i18next.t('Change_Password PG Password must contain at least one numeric character'))
        .matches(/[!@#$%^&*(),.?":{}|<>]/, i18next.t('Change_Password PG Password must contain at least one special character'))
        .required(i18next.t('Change_Password PG New password is required'))
        .min(8, i18next.t('Change_Password PG Password must be at least 8 characters'))
        .max(20, i18next.t('Change_Password PG Password must be at most 20 characters')),
      new_confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], i18next.t('Register PG Both passwords need to be the same!'))
        .required(i18next.t('Change_Password PG New confirm password is required'))
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit1');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          old_password: values.old_password,
          new_password: values.new_password,
          new_confirm_password: values.new_confirm_password,
        });
        const res = await postRequest('profile-change-password', jsonpar);
        if (res && res.code === 200) {
          validation1.resetForm();
          
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'success',
            message: res.message,
          }));
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();
        
        submit1.disabled = false;
        submit1.textContent = i18next.t('Change_Password PG Update Password');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });


  return (
    <React.Fragment>
			{AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          <Breadcrumb title={i18next.t('PG title Tokenizedgreen')} breadcrumbItem={i18next.t('PG breadcrumbItem Change_Password')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <Col lg={5} md={6} xs="12">
                          <div className="mb-3">
                            <Label className="form-label">{i18next.t('Change_Password PG Old password')}</Label>
                            <Input 
                              name="old_password"
                              type="password" 
                              placeholder={i18next.t('Change_Password PG Enter old password')} 
                              onChange={validation1.handleChange}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.old_password || ""}
                              invalid={
                                validation1.touched.old_password && validation1.errors.old_password ? true : false
                              }
                            />
                            {validation1.touched.old_password && validation1.errors.old_password ? (
                              <FormFeedback type="invalid">{validation1.errors.old_password}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={5} md={6} xs="12">
                          <div className="mb-3">
                            <Label className="form-label">{i18next.t('Change_Password PG New password')}</Label>
                            <Input 
                              name="new_password"
                              type="password" 
                              placeholder={i18next.t('Change_Password PG Enter new password')} 
                              onChange={validation1.handleChange}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.new_password || ""}
                              invalid={
                                validation1.touched.new_password && validation1.errors.new_password ? true : false
                              }
                            />
                            {validation1.touched.new_password && validation1.errors.new_password ? (
                              <FormFeedback type="invalid">{validation1.errors.new_password}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={5} md={6} xs="12">
                          <div className="mb-3">
                            <Label className="form-label">{i18next.t('Change_Password PG New confirm password')}</Label>
                            <Input 
                              name="new_confirm_password"
                              type="password" 
                              placeholder={i18next.t('Change_Password PG Enter new confirm password')} 
                              onChange={validation1.handleChange}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.new_confirm_password || ""}
                              invalid={
                                validation1.touched.new_confirm_password && validation1.errors.new_confirm_password ? true : false
                              }
                            />
                            {validation1.touched.new_confirm_password && validation1.errors.new_confirm_password ? (
                              <FormFeedback type="invalid">{validation1.errors.new_confirm_password}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <div className="text-left mt-4">
                          <Button
                            id="submit1"
                            onClick={(e) => {
                              e.preventDefault();
                              validation1.handleSubmit();
                              return false;
                            }}
                           type="button" color="primary">{i18next.t('Change_Password PG Update Password')}</Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Changepassword);
